import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { ScrollItem__SliderImages__Item } from '../scroll/ScrollItem__SliderDefault';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export default class ProductsList {
    container;
    sliders = [];
    scroller;
    holder;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__container) {
        this.container = __container;

        this.holder = GetBy.class('__infinite-holder', this.container)[0];
        this.scroller = new InfiniteScroll(InfiniteScroll.AXIS_Y, {
            domResize: this.container,
            appendContainer: this.holder,
            container: this.holder,
            multiplicator: 1,
            inverted: false,
            gap: 0,
            hasVirtuaScroll: true
        });

        this._call = () => this.loop();

        const sliders = GetBy.selector('[data-class="SliderDefault"]', this.container);
        for (let i = 0; i < sliders.length; i++) {
            const slider = new SliderScroll(sliders[i], {
                interaction: true,
                hasScrollbar: false,
                itemClass: ScrollItem__SliderImages__Item
            });

            this.sliders.push(slider);
        }
    }

    show() {
        // gsap.ticker.add(this._call);

        this.scroller.start();
        this.scroller.show();
    }

    hide() {
        this.scroller.hide();
    }

    loop() {
        this.scroller.loop();

        for (let i = 0; i < this.sliders.length; i++) {
            this.sliders[i].loop();
        }
    }

    dispose() {
        for (let i = 0; i < this.sliders.length; i++) {
            this.sliders[i].dispose();
        }
        // gsap.ticker.remove(this._call);
        this.scroller.dispose();
    }

    resize() {
        for (let i = 0; i < this.sliders.length; i++) {
            this.sliders[i].resize();
        }
        this.scroller.resize();
    }
}