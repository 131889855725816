import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    _container;

    static init() {
        this._container = GetBy.id('Preloader');
    }

    static update(__progress) {
        if (isDebug) return;

        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: __progress,
            ease: Power1.easeOut,
            duration: .1
        });
    }

    static hide(__call) {
        gsap.to(this._container, {
            duration: .1,
            opacity: 0,
            delay: 0,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
