import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics, isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import Forms from '../_app/cuchillo/forms/FormValidator';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import ProductsList from '../components/ProductList';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class Home extends Page {
  cards;
  filters;

  _calls = {
    filter: e => {
      const btn = e.target;
      const categ = `__categ-${btn.dataset.categ}`;

      for (let i = 0; i < this.cards.length; i++) {
        this.cards[i].classList.remove('--hidden');
      }

      if (btn.classList.contains('--active')) {
        btn.classList.remove('--active');
      } else {
        for (let i = 0; i < this.filters.length; i++) {
          const f = this.filters[i];

          if (f !== btn) f.classList.remove('--active');
          else f.classList.add('--active');
        }

        for (let i = 0; i < this.cards.length; i++) {
          const card = this.cards[i];
          if (!card.classList.contains(categ)) {
            this.cards[i].classList.add('--hidden');
          }
        }
      }
    },
  }

  constructor() {
    super();

    Videos.init();
    Acordions.init();
    Forms.init();

    if (!isMobile) {
      this.infiniteScroll = new ProductsList(GetBy.class('__products-list')[0]);
      document.body.classList.add('__noScroll');
    }

    this.filters = GetBy.class('__filter', this.container);
    this.cards = GetBy.class('__card', this.container);

    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i].addEventListener(Basics.clickEvent, this._calls.filter);
    }
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    // Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    if (isMobile) Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();

    if (isMobile) {
      Scroll.show();
      Scroll.start();
    } else {
      this.infiniteScroll.show();
    }
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();

    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i].removeEventListener(Basics.clickEvent, this._calls.filter);
    }
  }

  hide__effect() {
    if (isMobile) {
      Scroll.hide();
    } else {
      this.infiniteScroll.hide();
    }

    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    super.afterHide();

    if (isMobile) {
      Scroll.dispose();
    } else {
      this.infiniteScroll.dispose();
    }
  }

  // RESIZE
  resize() {
    super.resize();

    if (!isMobile) this.infiniteScroll.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();

      if (!isMobile) this.infiniteScroll.loop();
    }
  }
}

ControllerPage._addPage('home', Home);
